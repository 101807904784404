<template>
    <div class="soundboard">
        <div class="soundboard__header">
            <h1>Soundboard</h1>
            <div class="soundboard__header__options">
                <div class="search">
                    <input
                        type="text"
                        v-model="search"
                        placeholder="Search..."
                        name=""
                        id=""
                    />
                </div>
                <img
                    @click="layout = 'list'"
                    src="@/assets/img/list.svg"
                    alt="list"
                    :class="{ active: layout === 'list' }"
                />
                <img
                    @click="layout = 'grid'"
                    src="@/assets/img/grid.svg"
                    alt="grid"
                    :class="{ active: layout === 'grid' }"
                />
            </div>
        </div>
        <div class="soundboard__list" :class="layout">
            <div
                class="item"
                @click="playCommand(command)"
                v-for="command in searchedCommands"
                :key="command[0]"
            >
                <p>{{ command[0] }}</p>
                <div class="controls" v-if="layout == 'list'">
                    <img src="@/assets/img/play.svg" alt="preview" />
                    <img src="@/assets/img/more-horizontal.svg" alt="menu" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Soundboard",
    data() {
        return {
            commands: [],
            search: null,
            get layout() {
                return localStorage.getItem("layout") || "list";
            },
            set layout(value) {
                localStorage.setItem("layout", value);
            },
        };
    },
    methods: {
        getCommands() {
            this.$axios({
                method: "get",
                url: "/commands",
            }).then((res) => {
                const filterObject = (obj, filter, filterValue) =>
                    Object.keys(obj).reduce(
                        (acc, val) =>
                            obj[val][filter] === filterValue
                                ? acc
                                : {
                                      ...acc,
                                      [val]: obj[val],
                                  },
                        {}
                    );

                this.commands = filterObject(res.data, "type", "image");

                // Convert to array to make it easier to search
                this.commands = Object.entries(this.commands);
            });
        },
        playCommand(command) {
            this.$axios({
                method: "post",
                url: "/play-command",
                data: {
                    command: command[0],
                },
                headers: { "Content-Type": "application/json" },
            }).then({});
        },
    },
    mounted() {
        this.getCommands();
    },
    computed: {
        searchedCommands() {
            if (this.search) {
                return this.commands.filter((command) => {
                    return command[0]
                        .toUpperCase()
                        .match(this.search.toUpperCase());
                });
            } else {
                return this.commands;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.search {
    margin-bottom: 1rem;
    input {
        width: 100%;
        min-width: 400px;
        font-family: "Manrope";
        color: #fff;
        font-size: 16px;
        background: none;
        border: none;
        border-bottom: $dark 1px solid;
        padding: 10px;
        transition: 0.3s ease;
        outline: none;
        &:focus {
            border-bottom: #fff 1px solid;
        }
    }
    @media (max-width: 1200px) {
        display: none;
    }
}

.soundboard {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        &__options {
            display: flex;
            align-items: center;
            img {
                width: 30px;
                height: 30px;
                margin-left: 20px;
                cursor: pointer;
                opacity: 0.5;
                transition: 0.3s ease;

                &.active {
                    opacity: 1;
                }

                &:hover {
                    opacity: 0.7;
                    transform: scale(1.05);
                }
            }
        }
    }

    &__list {
        &.grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
            grid-gap: 20px;

            .item {
                position: relative;
                padding: 20px;
                background: $dark;
                text-align: center;
                font-weight: bold;
                cursor: pointer;
                transition: 0.3s ease;
                &:hover {
                    background: $primary;
                }
            }
        }

        &.list {
            display: grid;
            grid-column-gap: 50px;
            grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));

            .item {
                position: relative;
                border-bottom: $dark 1px solid;
                padding: 20px 90px 20px 20px;
                font-weight: bold;
                cursor: pointer;
                white-space: nowrap;
                transition: 0.3s ease;
                &:hover {
                    background: $dark;

                    .controls {
                        img {
                            opacity: 1;
                        }
                    }
                }

                .controls {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 100%;
                    right: 0;
                    display: grid;
                    grid-auto-flow: column;
                    align-items: center;
                    img {
                        height: 100%;
                        width: 45px;
                        padding: 10px;
                        object-fit: contain;
                        object-position: center;
                        opacity: 0.4;
                        transition: 0.3s ease;

                        &:hover {
                            background: $primary;
                        }
                    }
                }
            }
        }
    }
}
</style>
